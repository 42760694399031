import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, NavLink } from 'react-router-dom';
import { Menu, MessageSquare, Calendar, Upload as UploadIcon, Moon, Sun, Info } from 'lucide-react';
import { ThemeContext } from './ThemeContext';
import Chat from './Chat';
import Upload from './Upload';
import AboutUs from './AboutUs';
import './App.css';

const App = () => {
  const [events, setEvents] = useState([]);
  const [darkMode, setDarkMode] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [chatStarted, setChatStarted] = useState(false);

  const fetchEvents = useCallback(async () => {
    try {
      const response = await fetch('https://backend-service-zy7n4yqhlq-ue.a.run.app/events');
      if (response.ok) {
        const data = await response.json();
        console.log('Events fetched:', data);
        setEvents(data);
      } else {
        console.error('Failed to fetch events');
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleEventsClick = () => {
    window.open('https://pssmglobal.org/events/', '_blank');
    setMenuOpen(false);
  };

  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      <Router>
        <div className={`app-container ${darkMode ? 'dark-mode' : ''}`}>
          <header className={`app-header ${chatStarted ? 'chat-started' : ''}`}>
            <div className="header-title">PSSM.AI</div>
            <div className="header-icons">
              <Menu className="header-icon" onClick={toggleMenu} />
            </div>
          </header>
          {menuOpen && (
            <div className="menu-dropdown">
              <NavLink to="/chat" className="menu-item" onClick={toggleMenu}>
                <MessageSquare size={18} />
                Chat
              </NavLink>
              <button className="menu-item" onClick={handleEventsClick}>
                <Calendar size={18} />
                Events
              </button>
              <NavLink to="/upload" className="menu-item" onClick={toggleMenu}>
                <UploadIcon size={18} />
                Upload
              </NavLink>
              <NavLink to="/about" className="menu-item" onClick={toggleMenu}>
                <Info size={18} />
                About Us
              </NavLink>
              <button className="menu-item" onClick={toggleDarkMode}>
                {darkMode ? <Sun size={18} /> : <Moon size={18} />}
                {darkMode ? 'Light Mode' : 'Dark Mode'}
              </button>
            </div>
          )}
          <main className="main-content">
            <Routes>
              <Route path="/chat" element={<Chat onChatStart={() => setChatStarted(true)} />} />
              <Route path="/upload" element={<Upload onEventCreated={fetchEvents} />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/" element={<Navigate replace to="/chat" />} />
            </Routes>
          </main>
        </div>
      </Router>
    </ThemeContext.Provider>
  );
};

export default App;
import React from 'react';
import { Helmet } from 'react-helmet';
import { Info } from 'lucide-react';
import './AboutUs.css';

const AboutUs = () => {
  // You can update this version number as needed
  const appVersion = "1.0.19";

  return (
    <div className="about-us-wrapper">
      <div className="about-us-container">
        <Helmet>
          <title>About PSSM.AI - Wisdom of Brahmarshi Patriji Through AI</title>
          <meta name="description" content="PSSM.AI is an innovative AI chatbot sharing the wisdom of Brahmarshi Patriji. Learn about meditation, spiritual science, and the Pyramid Spiritual Society Movement." />
          <meta name="keywords" content="PSSM.AI, Brahmarshi Patriji, meditation, spiritual science, Pyramid Spiritual Society Movement, AI chatbot" />
        </Helmet>

        <h1 className="about-us-title">About PSSM.AI and Brahmarshi Patriji</h1>
        
        <section className="about-section about-pssmai">
          <h2 className="section-title">PSSM.AI: Bridging Spiritual Wisdom and Modern Technology</h2>
          <p>PSSM.AI is a cutting-edge conversational AI designed to share the profound wisdom and knowledge created by Brahmarshi Patriji with the world. In an era where digital interaction is becoming increasingly prevalent, PSSM.AI serves as a bridge between spiritual wisdom and modern technology.</p>
          
          <div className="vision-mission">
            <div className="vision">
              <h3>Our Vision</h3>
              <p>To make the transformative teachings of Brahmarshi Patriji accessible to a global audience, empowering individuals to lead more mindful, purposeful, and enlightened lives through the convenience of AI-driven interactions.</p>
            </div>
            
            <div className="mission">
              <h3>Our Mission</h3>
              <p>To create an AI-powered platform that accurately represents and disseminates Patriji's teachings, adapting to the evolving ways people seek and consume information in the digital age.</p>
            </div>
          </div>
          
          <div className="why-pssmai">
            <h3>Why PSSM.AI Matters</h3>
            <ul className="feature-list">
              <li>Accessibility: Provides 24/7 access to spiritual wisdom, breaking geographical and time constraints.</li>
              <li>Personalization: Offers tailored guidance based on individual queries and needs.</li>
              <li>Adaptation to Modern Habits: Recognizes the shift towards digital platforms and chatbots as primary sources of information.</li>
              <li>Preservation of Wisdom: Ensures that Patriji's teachings continue to reach and impact future generations.</li>
              <li>Global Reach: Facilitates the spread of meditation and spiritual science to a worldwide audience.</li>
            </ul>
          </div>
        </section>

        <section className="about-section about-patriji">
          <h2 className="section-title">Brahmarshi Patriji: The Visionary Behind PSSM.AI</h2>
          <div className="patriji-intro">
            <img src="/Patriji-v1.jpg" alt="Brahmarshi Patriji" className="patriji-image" />
            <div className="patriji-bio">
              <p>Born in India in 1947, Brahmarshi Patriji's journey from a highly educated agricultural scientist to a spiritual luminary is nothing short of extraordinary. His formal education, culminating in an MSc in Agriculture with a specialization in Soil Science, laid the foundation for a life dedicated to nurturing the soil of human consciousness.</p>
              <p>Since the early 1970s, Patriji has immersed himself in the study of meditation and spiritual wisdom, exploring the works of over 10,000 gurus and spiritual masters. His voracious appetite for knowledge led him to read more than 50,000 books on enlightenment, spirituality, and the science of meditation.</p>
            </div>
          </div>
          
          <p>In 1979, Patriji experienced a profound enlightenment through Breath Meditation, an event that catalyzed a 360-degree transformation in his life. This pivotal moment set him on a path to awaken and enlighten others, culminating in his decision to leave a high-paying corporate job in 1992 to fully commit to his spiritual mission.</p>
          
          <div className="pssm-info">
            <h3>The Pyramid Spiritual Society Movement</h3>
            <p>Founded by Patriji in 1990, the Pyramid Spiritual Society Movement (PSSM) has grown into a global phenomenon. Today, over 140,000 meditators practice at PSSM meditation centers and pyramid houses worldwide. These centers, characterized as "non-religious, non-cult, non-profit voluntary organizations," are dedicated to spreading breath meditation, plant-based living, spiritual science, and the beneficial uses of pyramid energy.</p>
            <p>PSSM centers across India pioneer the science of pyramid meditation, conducted within the sacred geometry of pyramids. The largest of these centers can accommodate up to 10,000 people, while over 10,000 individuals have incorporated personal-sized meditation pyramids into their homes.</p>
          </div>
          
          <div className="achievements">
            <h3>Achievements and Contributions</h3>
            <ul className="achievement-list">
              <li>Authored over 60 books on spiritual science in Telugu and English</li>
              <li>Travels globally to teach self-realization and inner divinity</li>
              <li>Developed innovative techniques combining music with meditation</li>
              <li>Recipient of the Life-Time Achievement Award from the National Symposium on Science of Holistic Living and its Global Application</li>
            </ul>
          </div>
          
          <div className="key-message">
            <Info size={24} />
            <p>Patriji's enduring message resonates with simplicity and power: Meditation, Pyramids, and Spiritual Science have the capacity to transform individual lives and, by extension, the entire world.</p>
          </div>
        </section>

        {/* New version number component */}
        <div className="version-number">
          <p>App Version: {appVersion}</p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { Send } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { ThemeContext } from './ThemeContext';
import './Chat.css';

const Chat = ({ onChatStart }) => {
    const [message, setMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [showQuestions, setShowQuestions] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const chatHistoryRef = useRef(null);
    const chatBottomRef = useRef(null);
    const { darkMode } = useContext(ThemeContext);

    const predefinedQuestions = [
        "What is the purpose of meditation?",
        "How can I start a daily meditation practice?",
        "What are the benefits of group meditation?",
        "Can you explain the concept of mindfulness?"
    ];

    const setVh = useCallback(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setVh();
            setIsMobile(window.innerWidth <= 768);
        };

        const handleKeyboardResize = () => {
            const chatContainer = document.querySelector('.chat-container');
            const chatBottom = document.querySelector('.chat-bottom');
            const keyboardHeight = window.innerHeight - window.visualViewport.height;
            chatContainer.style.height = `calc(var(--vh, 1vh) * 100 - ${keyboardHeight}px)`;
            chatBottom.style.bottom = `${keyboardHeight}px`;
        };

        handleResize();
        window.visualViewport.addEventListener('resize', handleResize);
        window.visualViewport.addEventListener('resize', handleKeyboardResize);

        return () => {
            window.visualViewport.removeEventListener('resize', handleResize);
            window.visualViewport.removeEventListener('resize', handleKeyboardResize);
        };
    }, [setVh]);

    const scrollToBottom = useCallback(() => {
        if (chatBottomRef.current) {
            chatBottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [chatHistory, scrollToBottom]);

    const handleSend = useCallback(async (questionText = message) => {
        if (!questionText.trim()) return;
    
        if (showQuestions) {
            setShowQuestions(false);
            onChatStart();
        }

        const newChatHistory = [...chatHistory, { user: questionText, bot: '' }];
        setChatHistory(newChatHistory);
        setMessage('');
    
        try {
            const response = await fetch(`https://backend-service-zy7n4yqhlq-ue.a.run.app/chat`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ message: questionText })
            });
    
            if (response.ok) {
                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                let fullResponse = '';
    
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;
    
                    const chunk = decoder.decode(value);
                    const lines = chunk.split('\n');
                    
                    for (const line of lines) {
                        if (line.startsWith('data: ')) {
                            const data = line.slice(6).trim();
                            if (data === '[DONE]') {
                                break;
                            } else {
                                fullResponse += data + ' ';
                                // Process the response to convert bullet points to HTML
                                const processedResponse = fullResponse.trim()
                                .replace(/<br>/g, '\n')  // Replace <br> with newline
                                .replace(/•/g, '-')  // Replace bullet points with markdown list items
                                .split('\n')  // Split into lines
                                .map(line => line.trim())  // Trim each line
                                .join('\n');  // Join back into a single string
                                
                                // Wrap the entire response in a list if it contains list items
                                const wrappedResponse = processedResponse.includes('<li>') 
                                    ? `<ul>${processedResponse}</ul>` 
                                    : processedResponse;

                                setChatHistory(prev => {
                                    const updated = [...prev];
                                    updated[updated.length - 1].bot = wrappedResponse;
                                    return updated;
                                });
                            }
                        }
                    }
                }
                scrollToBottom();
            } else {
                const data = await response.json();
                alert(`Failed to send message: ${data.detail}`);
            }
        } catch (error) {
            console.error("Error sending message:", error);
            alert("An error occurred while sending the message.");
        }
    }, [chatHistory, message, showQuestions, onChatStart, scrollToBottom]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    return (
        <div className={`chat-container ${darkMode ? 'dark-mode' : ''}`}>
            {showQuestions && (
                <div className="chat-logo-container">
                    <img src="/LOGO.png" alt="Logo" className="chat-logo" />
                </div>
            )}
            <div className="chat-content">
                <div className="chat-history" ref={chatHistoryRef}>
                    {chatHistory.map((chat, index) => (
                        <div key={index} className="chat-message-container">
                            <div className="user-message">{chat.user}</div>
                            <div className="bot-message-container">
                                <img src="/LOGO.png" alt="AI Assistant" className="bot-logo" />
                                <div className="bot-message">
                                    <ReactMarkdown
                                        components={{
                                            p: ({node, ...props}) => <div {...props} />,
                                            ul: ({node, ...props}) => <ul style={{paddingLeft: '20px', margin: '10px 0'}} {...props} />,
                                            li: ({node, ...props}) => <li style={{marginBottom: '5px'}} {...props} />
                                        }}
                                    >
                                        {chat.bot}
                                    </ReactMarkdown>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div ref={chatBottomRef} />
                </div>
            </div>
            <div className="chat-bottom">
                {showQuestions && (
                    <div className="predefined-questions">
                        {predefinedQuestions.map((question, index) => (
                            <button 
                                key={index} 
                                className="question-button"
                                onClick={() => handleSend(question)}
                            >
                                {question}
                            </button>
                        ))}
                    </div>
                )}
                <div className="chat-input-container">
                    <input
                        type="text"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={handleKeyDown}
                        onFocus={() => setTimeout(scrollToBottom, 300)}
                        placeholder="How can PSSM.ai help?"
                        className="chat-input"
                    />
                    <button onClick={() => handleSend()} className="send-button">
                        <Send size={20} />
                    </button>
                </div>
                <div className="disclaimer">PSSM.ai can make mistakes. Check important information.</div>
            </div>
        </div>
    );
};

export default Chat;
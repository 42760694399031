import React, { useState, useRef, useEffect } from 'react';
import { Upload as UploadIcon, Trash2 } from 'lucide-react';
import './Upload.css';

const Upload = ({ onEventCreated }) => {
    const [file, setFile] = useState(null);
    const [password, setPassword] = useState('');
    const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
    const [dragActive, setDragActive] = useState(false);
    const [eventData, setEventData] = useState({
        title: '',
        description: '',
        date: '',
        location: '',
        image: null,
        registrationUrl: ''
    });
    const [events, setEvents] = useState([]);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (isPasswordCorrect) {
            fetchEvents();
        }
    }, [isPasswordCorrect]);

    const fetchEvents = async () => {
        try {
            const response = await fetch('https://backend-service-zy7n4yqhlq-ue.a.run.app/events');
            if (response.ok) {
                const data = await response.json();
                setEvents(data);
            } else {
                console.error('Failed to fetch events');
            }
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    };

    const handlePasswordSubmit = () => {
        if (password === 'Meditation123$') {
            setIsPasswordCorrect(true);
        } else {
            alert('Incorrect password');
        }
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setFile(e.dataTransfer.files[0]);
        }
    };

    const handleChange = (e) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            setFile(e.target.files[0]);
        }
    };

    const onButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleUpload = async () => {
        if (!file) {
            alert("Please select a file first.");
            return;
        }
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch('https://backend-service-zy7n4yqhlq-ue.a.run.app/upload', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                alert("File uploaded and processed successfully.");
                setFile(null);
            } else {
                const data = await response.json();
                alert(`Failed to upload file: ${data.detail}`);
            }
        } catch (error) {
            console.error("Error uploading file:", error);
            alert("An error occurred while uploading the file.");
        }
    };

    const handleEventSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const key in eventData) {
            if (key === 'image' && eventData[key]) {
                formData.append('image', eventData[key], eventData[key].name);
            } else {
                formData.append(key, eventData[key]);
            }
        }
    
        try {
            const response = await fetch('https://backend-service-zy7n4yqhlq-ue.a.run.app/events', {
                method: 'POST',
                body: formData,
            });
    
            if (response.ok) {
                alert('Event created successfully');
                onEventCreated();
                fetchEvents();  // Refresh the events list
                setEventData({
                    title: '',
                    description: '',
                    date: '',
                    location: '',
                    image: null,
                    registrationUrl: ''
                });
            } else {
                const errorData = await response.json();
                alert(`Failed to create event: ${errorData.detail || 'Unknown error'}`);
            }
        } catch (error) {
            console.error('Error creating event:', error);
            alert('An error occurred while creating the event');
        }
    };

    const handleDeleteEvent = async (eventId) => {
        try {
            const response = await fetch(`https://backend-service-zy7n4yqhlq-ue.a.run.app/events/${eventId}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                alert('Event deleted successfully');
                fetchEvents();  // Refresh the events list
            } else {
                const errorData = await response.json();
                alert(`Failed to delete event: ${errorData.detail || 'Unknown error'}`);
            }
        } catch (error) {
            console.error('Error deleting event:', error);
            alert('An error occurred while deleting the event');
        }
    };

    if (!isPasswordCorrect) {
        return (
            <div className="password-container">
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter password"
                    className="password-input"
                />
                <button onClick={handlePasswordSubmit} className="submit-button">
                    Submit
                </button>
            </div>
        );
    }

    return (
        <div className="upload-container">
            <h1>Upload a file</h1>
            <div 
                className={`drag-drop-area ${dragActive ? "drag-active" : ""}`}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
            >
                <input
                    ref={fileInputRef}
                    type="file"
                    onChange={handleChange}
                    className="file-input"
                />
                <label htmlFor="file-input" className="file-label">
                    {file ? file.name : "Drag and drop a file here, or click to select"}
                </label>
                <button className="select-file-button" onClick={onButtonClick}>
                    <UploadIcon size={20} />
                    Select File
                </button>
            </div>
            {file && <button onClick={handleUpload} className="upload-button">Upload File</button>}

            <h2>Create New Event</h2>
            <form onSubmit={handleEventSubmit} className="event-form">
                <input
                    type="text"
                    value={eventData.title}
                    onChange={(e) => setEventData({...eventData, title: e.target.value})}
                    placeholder="Event Title"
                    required
                />
                <textarea
                    value={eventData.description}
                    onChange={(e) => setEventData({...eventData, description: e.target.value})}
                    placeholder="Event Description"
                    required
                />
                <input
                    type="date"
                    value={eventData.date}
                    onChange={(e) => setEventData({...eventData, date: e.target.value})}
                    required
                />
                <input
                    type="text"
                    value={eventData.location}
                    onChange={(e) => setEventData({...eventData, location: e.target.value})}
                    placeholder="Event Location"
                    required
                />
                <input
                    type="file"
                    onChange={(e) => setEventData({...eventData, image: e.target.files[0]})}
                    accept="image/*"
                    required
                />
                <input
                    type="url"
                    value={eventData.registrationUrl}
                    onChange={(e) => setEventData({...eventData, registrationUrl: e.target.value})}
                    placeholder="Registration URL"
                    required
                />
                <button type="submit" className="create-event-button">Create Event</button>
            </form>

            <h2>Existing Events</h2>
            <div className="events-list">
                {events.map((event) => (
                    <div key={event.id} className="event-item">
                        <span>{event.title}</span>
                        <button onClick={() => handleDeleteEvent(event.id)} className="delete-button">
                            <Trash2 size={20} />
                            Delete
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Upload;